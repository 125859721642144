/**
 * Third-party libraries
 */
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
/**
 * First-party files
 */
//component
import Layout from "components/Layout"
import ThirdLayerHeader from "components/ThirdLayerHeader"
import ThirdLayerClose from "components/ThirdLayerClose"
import { ThirdLayer, Main } from "components/ThirdLayerBody"
import SwipeMotionDiv from "components/ui/SwipeMotionDiv"
import SEO from '../../components/SEO'


const StyledP = styled.p`
  font-size: 0.75em;
`

const Attention = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const parentPath = "/" + location.pathname.split("/")[1]

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.top.pass}`,
          "name": `${data.seo.top.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.page.bitsKnow.pass}`,
          "name": `${data.seo.page.bitsKnow.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `${data.seo.page.bitsKnow.attention.pass}`,
          "name": `${data.seo.page.bitsKnow.attention.title}`,
        }
      }]
    }
  ]

  return (
    <ThirdLayer>
      <Layout>
        <SEO
          title={data.seo.page.bitsKnow.attention.title}
          pageUrl={data.seo.page.bitsKnow.attention.pass}
          description={data.seo.page.bitsKnow.attention.description}
          imgUrl='/mainVisual.jpg'
          type='article'
          jsonld={jsonld}
        />
        <SwipeMotionDiv
          entry={entry}
          exit={exit}
          transitionStatus={transitionStatus}
        >
          <ThirdLayerHeader
            text={data.text.attention.leadText}
            parentPath={parentPath}
          />
          <Main>
            <p>{data.text.attention.paragraph1}</p>
            <p>{data.text.attention.paragraph2}</p>
            <StyledP>{data.text.attention.ref}</StyledP>
          </Main>
          <ThirdLayerClose parentPath={parentPath} />
        </SwipeMotionDiv>
      </Layout>
    </ThirdLayer>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "bitsOfKnowledgePage" }) {
      attention {
        leadText
        paragraph1
        paragraph2
        ref
        title
      }
      tendToLack {
        leadText
        paragraph1
        title
      }
      supporter {
        leadText
        title
        insolubleBlock {
          blockTitle
          comment
          innerBoxText
          paragraph1
          paragraph2
        }
        waterSolubleBlock {
          blockTitle
          comment
          innerBoxText
          paragraph1
        }
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        bitsKnow {
          description
          pass
          title
          attention {
            title
            pass
            description
          }
        }
      }
    }
  }
`

export default Attention
